import i18next from 'i18next';

import { Unit } from 'models';

export const getFormattedUnit = (unit: Unit): string => {
  switch (unit) {
    case Unit.MGDL:
      return i18next.t('mg/dL');
    case Unit.MMOLL:
      return i18next.t('mmol/L');
    case Unit.PPM:
      return i18next.t('PPM');
    case Unit.ACE:
      return i18next.t('ACE');
    case Unit.GRAMS:
      return i18next.t('g');
    case Unit.KCAL:
      return i18next.t('kcal');
    case Unit.CM:
      return i18next.t('cm');
    case Unit.INCH:
      return i18next.t('in');
    case Unit.LBS:
      return i18next.t('lbs');
    case Unit.KG:
      return i18next.t('kg');
    case Unit.NO_UNIT:
      return '';
    case Unit.IU:
      return i18next.t('IU');
    case Unit.MCG:
      return i18next.t('mcg');
    case Unit.MG:
      return i18next.t('mg');
    case Unit.BPM:
      return i18next.t('bpm');
    case Unit.MS:
      return i18next.t('ms');
    case Unit.MMHG:
      return i18next.t('mmHg');
    case Unit.KG_M2:
      return i18next.t('kg/m2');
    case Unit.S:
      return i18next.t('');
    case Unit.MLUL:
      return i18next.t('mlU/L');
    case Unit.NGDL:
      return i18next.t('ng/dL');
    case Unit.NMOLL:
      return i18next.t('nmol/L');
    case Unit.PMOLL:
      return i18next.t('pmol/L');
    case Unit.PERCENTAGE:
      return i18next.t('%');
    case Unit.MCMOLL:
      return i18next.t('µmol/L');
    case Unit.GL:
      return i18next.t('g/L');
    case Unit.MMOL_MOL:
      return i18next.t('mmol/mol');
    case Unit.MCUL:
      return i18next.t('µU/L');
    case Unit.MCUML:
      return i18next.t('µU/mL');
    case Unit.MCGDL:
      return i18next.t('µg/dL');
    case Unit.PGML:
      return i18next.t('pg/mL');
    case Unit.MGL:
      return i18next.t('mg/L');
    case Unit.UL:
      return i18next.t('U/L');
    case Unit.MEQL:
      return i18next.t('mEq/L');
    case Unit.GDL:
      return i18next.t('g/dL');
  }
};
